import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './index.scss';


import {UserContextProvider} from "./store/userContext";
import {BatpermContextProvider} from "./batperm/BatpermContext";

import NyhetsBrevKNBF from "./pages/NyhetsBrevKNBF";

document.title = "Båtens Verden";
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

// Lazy load Batperm components
const BatpermApp = lazy(() => import('./BatpermApp'));
const BatpermQrCode = lazy(() => import('./batperm/pages/Batperm-qr-code'));

const BatensVerdenApp = lazy(() => import('./BatensVerdenApp'));
const BatgledeApp = lazy(() => import('./BatgledeApp'));

const BatpermQR = () => {
	return (
		<Suspense fallback={<div>Laster...</div>}>
			<BatpermContextProvider>
				<Routes>
					<Route path={'/:QRCodeId'} element={<BatpermQrCode />} />
				</Routes>
			</BatpermContextProvider>
		</Suspense>
	);
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter basename={''}>
		<UserContextProvider>
			<Routes>
				<Route path={'/*'} element={<Suspense fallback={<div className={'centered'}></div>}><BatensVerdenApp /></Suspense>} />
				<Route path="/nyhetsbrev-knbf" element={<NyhetsBrevKNBF />} />
				<Route path={'/batperm/qr/*'} element={<BatpermQR />} />
				<Route path={'/batperm/*'} element={
					<Suspense fallback={<div className={'centered'}>Laster...</div>}>
						<BatpermApp />
					</Suspense>
				} />
				<Route path={'/batglede/*'} element={
					<Suspense fallback={<div className={'centered'}></div>}>
						<BatgledeApp />
					</Suspense>
				} />
			</Routes>
		</UserContextProvider>
	</BrowserRouter>
);
